<template>
  <!--控制台：印刷管家-->
  <div class="print-page">
    <!--左侧导航-->
    <keep-alive>
      <sidebar-left />
    </keep-alive>
    <!--内容-->
    <main>
      <router-view />
    </main>
  </div>
</template>

<script>
import sidebarLeft from '@/components/sidebar-left';
// import cookies from '@/utils/cookies'
export default {
  name: 'PrintSteward',
  components: {
    sidebarLeft,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.print-page {
  display: flex;
  width: 100%;
  height: calc(100vh - 62px);
}
main {
  flex: 1 1 auto;
  width: calc(100% - 200px);
  //padding: 10px 20px 0 20px;
  overflow: auto;
}
</style>
